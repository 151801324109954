import React from 'react';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { getPage, getSite, getTranslations } from 'cms-base';

import DynamicSection, { Component } from 'cms-base/components/DynamicSection';

import { getLayout } from './[[...slug]]';

import pageQuery from '../graphql/Page.gql';

export const getStaticProps: GetStaticProps = async ({ locale, locales }) => {
    const { page } = await getPage('error', locale, pageQuery);
    const { site, header, footer } = await getSite(locale);
    const translations = await getTranslations(locale);

    return {
        props: {
            page,
            site,
            header,
            footer,
            translations,
            locale,
            locales,
        },
    };
};

const NotFoundPage = ({
    page,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
    const sections = page?.sections;

    return sections?.length > 0
        ? sections.map((section: Component) => (
              <DynamicSection
                  {...section}
                  key={section?.id ?? section?.originalId}
              />
          ))
        : null;
};

NotFoundPage.getLayout = getLayout;

export default NotFoundPage;
